import TssModal from './modules/modal'

window.onload = function() {
  const body = document.getElementsByTagName('body')[0]
  let webcamModal

  /* Messages */
  function handleMessage(e) {
    if (e.data.type === 'tss:open-modal') {
      if (!webcamModal) webcamModal = new TssModal(body)
      webcamModal.open(e.data.url)
    }
  }

  window.addEventListener('message', handleMessage, false)
}
